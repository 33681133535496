

/**
 *  公共mixins
 *  
 **/ 
var commonMixin = {
	data(){
		
	},
	methods:{
		// 获取保险产品下单入参  用于机票。火车票下单
		getBookBxBean(bxList,cxrList){
			bxList = bxList || [];
			cxrList = cxrList || [];
			var bookBxBean = {};
			var bxcpList = [];
			var psgList = [];
			// 获取保险产品
			for(var k=0;k<bxList.length;k++){
				var item = bxList[k] || {} ;
				if (item.checked) {
					bxcpList.push({
						"bxid": item.bxid,
						"bxmc": item.bxmc,
						"bxxz": item.bxxz,
						"bxxzmc": item.bxxzmc,
						"saleprice": item.saleprice,
						"sxgz": item.sxgz,
						"bxly": item.bxly,
						"purchaseprice": item.purchaseprice,
					})
				}
				
			}
			// 获取出行人
			if(bxcpList.length>0){
				var numb = 0;
				for (var i = 0; i < cxrList.length; i++) {
					numb += 1;
					psgList.push({
						"birthDate": cxrList[i].born || '',
						"sn": numb
					});
				}
			}else{
				return bookBxBean
			}
			bookBxBean.bxcpList = bxcpList;
			bookBxBean.psgList = psgList;
			return bookBxBean	
		},
		
		
		
	}
}


export default commonMixin