<template>
	<div class="   popups popup-comfortinfo"
		style="vertical-align: top; text-align: left; width: 622px; padding: 0px; ">
		<div class="title">航班信息<span class="hint"></span></div>
		<div class="body">
			<div class="comfort-detail">
				<div class="airline-box">
					<div class="airline"> 
						<img class="logo" :src="flightItem.airlineImg">
						<span class="name">{{flightItem.airlineName}} {{flightItem.flightNo}}</span>
					</div>
					<div class="shareline" v-show="flightItem.sharFlightNo">
						&nbsp;
						<i class="iconfont yj-icon-arrow_turn_down_right" style="font-size: 18px;margin-top: 10px;">&nbsp;</i> 
						<img class="logo" :src="flightItem.shareAirlineImg">
						<span class="name">实际承运 {{flightItem.shareAirlineName}} {{flightItem.sharFlightNo}}
							<span class="hint">（请您前往实际承运的航司值机柜台办理登机手续）</span>
						</span>
					</div>
				</div>
				<div class="plane-model">
					<div class="model-title"> 
							<span class="tit-item"><span class="label">计划机型：</span>{{flightItem.planeName || '暂无'}}</span>
							<span class="tit-item"><span class="label">类型：</span>{{flightItem.planeBody || '暂无'}}</span>
							<span class="tit-item"><span class="label">餐食：</span>{{flightItem.hasFood==1?'有':'无'}}</span>
							<span class="tit-item"><span class="label">机建：</span><span class="blue">¥{{flightItem.airportFee}}</span></span>
						    <span class="tit-item"><span class="label">燃油税：</span><span class="blue">¥{{flightItem.tax}}</span></span> </div>
				</div>
			</div>
			<div class="disclaimer">机型仅供参考，以航空公司当天实际安排为准，如有变动，敬请谅解</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:"FlightInfo",
		data(){
			return {
				
			}
		},
		props:{
			flightItem:{
				type:[Object],
				default (){
					return {}
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	/* 航班信息弹窗 */
	.popups {
	    display: inline-block;
	    background-color: #fff;
	    -webkit-box-shadow: 0 16px 40px rgba(0, 0, 0, .15);
	    box-shadow: 0 16px 40px  rgba(0, 0, 0, .15);
	}
	.popups .title {
	    position: relative;
	    background-color: #91a4c3;
		/* background-color: #0983df; */
	    color: #fff;
	    font-size: 18px;
	    line-height: 1;
	    padding: 10px 18px;
	}
	.popup-comfortinfo .comfort-detail {
	    line-height: 1;
	    padding: 16px 20px;
	}
	.popup-comfortinfo .comfort-detail .airline-box {
	    margin-bottom: 10px;
	}
	.popup-comfortinfo .comfort-detail .airline-box .logo {
	    height: 18px;
	    width: 18px;
	    margin-right: 4px;
	}
	.popup-comfortinfo .comfort-detail .airline-box .logo, .popup-comfortinfo .comfort-detail .airline-box .name {
	    display: inline;
	    vertical-align: middle;
	}
	.popup-comfortinfo .comfort-detail .airline-box .name {
	    font-size: 16px;
	    font-weight: 500;
	    margin-right: 8px;
	    color: #354047;
	}
	/* 共享 */
	.popup-comfortinfo .comfort-detail .airline-box .shareline .name {
	    font-size: 12px;
	    font-weight: 400;
	}
	
	/* 机型 */
	.popup-comfortinfo .comfort-detail .plane-model {
	    border-top: 1px solid #eaeaea;
	}
	.popup-comfortinfo .comfort-detail .plane-model .model-title {
	    font-size: 14px;
	    font-weight: 700;
	    padding: 15px 0 2px 24px;
	}
	.popup-comfortinfo .comfort-detail .plane-model .model-title .tit-item {
		display: inline-block;
	    margin-right: 20px;
	}
	.tit-item .label{
		color: #808080;
	}
	/* 描述 */
	.popup-comfortinfo .disclaimer {
	    padding: 15px 20px 15px;
	    font-size: 12px;
	    color: #999;
	    text-align: center;
	}
</style>
