<template>
	
	<div class="tooltip tooltip-table domestic-tooltip-table tooltip-content-no-scrollBar">
		<div class="tooltip-content ">
			<div>
				<table>
					<tbody>
						<tr>
							<th class="tit" rowspan="4"><strong>成人票</strong></th>
							<td class="sub-tit">退票规则</td>
							<td class="detail" v-html="priceItem.refundRule?priceItem.refundRule.replace(/；|。|;/g,';</br>'):''">
							</td>
					
						</tr>
						<tr>
							<td class="sub-tit">改签规则</td>
							<td class="detail" v-html="priceItem.changeRule?priceItem.changeRule.replace(/；|。| ;  /g,';</br>'):''">
								
							</td>
						
						</tr>
						<tr>
							<td class="sub-tit">签转条件</td>
							<td class="detail">{{priceItem.upgradeRule}}</td>
						</tr>
						<tr>
							<td class="sub-tit col-2-title" >行李额</td>
							<td>{{priceItem.baggage | filterBaggage}}</td>
						</tr>
						<!-- <tr>
							<td class="sub-tit col-2-title" colspan="2" rowspan="2">行李额</td>
							<td>免费手提行李额: 5KG，每人1件，单件行李质量不超过5KG，三边之和小于（等于）115CM</td>
						</tr>
						<tr>
							<td>免费托运行李额: 20KG，不限件数，单件托运行李质量不超过50KG，单件体积不超过40×60×100CM</td>
						</tr> -->
						<tr>
							<td class="sub-tit col-2-title" colspan="2">报销凭证</td>
							<td>可提供”行程单“作为报销凭证，凭证金额以实际开具金额为准， 支付成功后可查看订单并开具凭证。</td>
						</tr>
						<!-- <tr>
							<td class="sub-tit col-2-title" colspan="2">里程积分</td>
							<td>累计2464航司里程</td>
						</tr> -->
				<!-- 		<tr>
							<td colspan="3">
								<div class="sub-desc orange-desc">
									<p class="desc-title">· 空港嘉华不加收任何退改服务费，具体费用按产品提供方规定收取</p>
								</div>
							</td>
						</tr> -->
					</tbody>
				</table>
        <div style="display: flex;">
          <table v-if="priceItem.refundRuleList && priceItem.refundRuleList.length>0">
            <tbody>
              <tr>
                <th colspan="2" style="text-align: center;">退票费</th>
              </tr>
              <tr v-for="(item, index) in priceItem.refundRuleList" :key="index">
                <td>{{item.date}}</td>
                <td v-if="item.charge>=0 || item.charge<0"><span :class="{orange:item.enable}">￥{{item.charge}}</span> /人</td>
                <td v-else :class="{orange:item.enable}">{{item.charge}}</td>
              </tr>
            </tbody>
          </table>
          <table v-if="priceItem.changeRuleList && priceItem.changeRuleList.length>0">
            <tbody>
              <tr>
                <th colspan="2" style="text-align: center;">同舱改期费</th>
              </tr>
              <tr v-for="(item, index) in priceItem.changeRuleList" :key="index">
                <td>{{item.date}}</td>
                <td v-if="item.charge>=0 || item.charge<0"><span :class="{orange:item.enable}">￥{{item.charge}}</span> /人</td>
                <td v-else :class="{orange:item.enable}">{{item.charge}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:"ProductRule",
		data(){
			return{
				priceItemInfo:{},
			}
		},
		props:{
			priceItem:{
				type:Object,
				default (){
					return {}
				}
			},
		},
		methods:{
			
		},
    watch:{
      priceItem(val){
        // console.log(val)
      }
    }
	}
</script>

<style scoped lang="scss">
  .orange{
    color: orange;
  }
		.domestic-tooltip-table{
			    width: 1000px;
			    max-width: 1000px;
		}
		.tooltip{
			padding: 15px;
			box-shadow: 0 4px 8px rgba(204,204,204,.8);
			-webkit-box-shadow:0 4px 8px  rgba(204,204,204,.8);
		}
		
		.domestic-tooltip-table.tooltip-table table {
		    border: 1px solid #ddd;
			border-collapse: collapse;
			    border-spacing: 0;
		}
		.tooltip-table.domestic-tooltip-table table .sub-table table {
		    border: none;
		}
		.tooltip-table table td, .tooltip-table table th {
		    text-align: left;
		    font-weight: 400;
		    padding: 7px 10px;
		    border-bottom: 1px solid #ccc;
		}
		.tooltip-table.domestic-tooltip-table.tooltip-table table .sub-tit,
		.tooltip-table.domestic-tooltip-table.tooltip-table table .tit {
		    border-right: 1px solid #ddd;
		    padding: 12px;
		    color: #333;
		    font-size: 14px;
		}
		.tooltip-table table .sub-table {
		    padding: 0;
		    vertical-align: top;
		    height: 1px;
		    min-height: 100%;
		}
		.tooltip-table{
				display: inline-block;
			    text-align: left;
			    background-color: #fff;
			   
			    line-height: 1.5;
		}
		.tooltip-table table{
			width: 100%;
		}
		.tooltip-table.domestic-tooltip-table table .detail-item {
		    min-width: 70px;
		}
		.tooltip-table table .col-1 {
		    width: 130px;
		    border-right: 1px solid #ccc;
		}
		
		// 底部
		.tooltip-table .sub-desc.orange-desc {
		    background-color: #fff8f2;
		}
		.tooltip-table .sub-desc {
		    background: #f2fbf8;
		    border-radius: 2px;
		    padding: 8px 16px;
		}
</style>
