/*
 *  出差申请单控制逻辑混入
 *  1.城市控制  10991 查询出差申请单可更改行程  0.1.2.3  默认值 0
 *   	0：不可修改  
 *   	1.不做限制，可任意更改  
 *   	2.行程中城市自由组合  
 *   	3.行程中城市  出发和到达限制可选
 *  2.日期控制    10992    查询出差申请单可变更日期  默认值1
 *      0： 不可更改：出发、到达日期不可以修改，严格按照日期出行  
 *      1： 按行程更改   ： 
 *               10993  查询出差申请单日期范围  默认 0天     前后几天
 * 				 -1   不做限制   
 * 				 0    机、火、用车不可修改 ，酒店按照入住和离店日期修改 
 * 				 n>0  前后n天范围内修改（需要在预订的合理范围内）
 *      2： 按出差申请单范围修改
 *          在出差申请单的合理范围内预订
 *
 */ 

var ccsqdMixin = {
	data(){
		return {
			
		}
	},
	methods:{
		/*
		 *  @param proType {Number，String  }  产品类型  10901 10902机票  10903 酒店  10904火车   10906用车
		 *  @param tripType {Number，String  } 因公因私  tripType: 1因公, 2因私
		 *  @param defaultDateRange {String | Number}  日期该业务下，最大日期范围  如机票 365 火车票最大销售日期 15 、酒店最大预订日期 60
		 *  @param ccsqdStartDate {String}  出差申请单开始日期
		 *  @param ccsqdEndDate {String}    出差申请单结束日期
		 *  @param routeStartDate {String}  行程开始日期
		 *  @param routeEndDate {String}    行程结束日期
		 *  
		 * 
		 *  @return {Object} 返回一个最大值和最小值的数组  {minDate,maxDate,noCanTips}
		 *  noCanTips  若有值，则不可预订
		 */
		getBookDateRange(proType,tripType,defaultDateRange,ccsqdId,routeId){
			let dateCanChange = this.$common.getVipParamterByParNo(this.$store,10992,'1')
			let dateChangeRange = this.$common.getVipParamterByParNo(this.$store,10993,'0')
			let chooseCcsqdObj  = this.$common.sessionget('chooseCcsqdObj') || {}; // 当前出差申请单
			let ccsqdDetail = chooseCcsqdObj.detail || {};
			let routeObj = chooseCcsqdObj.currentRoute || {};
			
			
			let bookStyle = this.$common.getUserInfo().vipCorp.bookStyle;
			let maxRange = 365;
			if(defaultDateRange){
				maxRange = defaultDateRange;
			}
			
			var noCanTips = '';
			var minDay = this.$common.getAppointDay(new Date(), 0);
			var maxDay = this.$common.getAppointDay(new Date(), maxRange);
			var nowDate = this.$common.getAppointDay(new Date(), 0);
			
			// 如果是因公出差单模式，处理下日期可选范围
			if(tripType == 1 && bookStyle ==2 && (chooseCcsqdObj.ccsqdId || chooseCcsqdObj.ccsqdNo) ){
				let routeStartDate = routeObj.routeStart || routeObj.departDate;
				let routeEndDate = routeObj.routeEnd || routeObj.arriveDate;
				if(dateCanChange == '1'){ // 按照行程更改
					if(dateChangeRange == '-1'){//不做限制
						
					}else if(dateChangeRange == '0'){// 按照既定行程日期预订： 酒店可在酒店行程日期范围内预订
						minDay = routeStartDate;
						maxDay = routeEndDate || routeStartDate;
					}else{ //大于0  前后几天
						dateChangeRange = Number(dateChangeRange)
						var calcMinDate = routeObj.routeStart || this.$common.getAppointDay(routeStartDate, -dateChangeRange);
						var calcMaxDate =  routeObj.routeEnd ||  this.$common.getAppointDay(routeEndDate || routeStartDate, dateChangeRange);
						minDay = calcMinDate > nowDate ? calcMinDate : nowDate;
						maxDay = calcMaxDate;
					}
				}else if(dateCanChange == '2'){//出差申请单范围内可以选择
					let ccsqdStartDate = routeObj.routeStart || ccsqdDetail.dateBegin;
					let ccsqdEndDate = routeObj.routeEnd || ccsqdDetail.dateEnd;
					// 出差申请单范围,需要和可预订范围比较处理 
					if (proType == 10903) {
						// 酒店直接用出差单的时间范围
						minDay = ccsqdStartDate;
						maxDay = ccsqdEndDate;
					} else {
						minDay = ccsqdStartDate > nowDate ? ccsqdStartDate : nowDate;
						maxDay = ccsqdEndDate>maxDay?maxDay:ccsqdEndDate;
					}
				}else{
					minDay = routeStartDate;
					maxDay = routeEndDate || routeStartDate;
				}
			}
			// 如果行程已过期
			if(maxDay<nowDate){
				minDay = this.$common.getAppointDay(nowDate,-1);
				maxDay = this.$common.getAppointDay(nowDate,-2);
				noCanTips = '您选择的行程已过期！'
			}
			return {minDay,maxDay,noCanTips}
		}	
	},
	

};




export default ccsqdMixin