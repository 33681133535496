<template>
	<div class="flight-list">
		<template v-for="(flightItem,flightIndex) in flightListCopy">
			<flight-item 
			v-show="!flightItem.isNoShow"
			:key="flightItem.flightNo" 
			:flightIndex ="flightIndex"
			:flightItem="flightItem"
			:priceMap="priceMap"
			:chooseFlight="chooseFlight"
			@flightZhanKai="flightZhanKai"
			@cabinTypeZhanKai="cabinTypeZhanKai"
			@chooseFlight="toChooseFlight"
			:tkQuery="tkQuery"
			></flight-item>
		</template>
	</div>
</template>

<script>
	import FlightItem from './FlightItem'
	export default{
		data(){
			return {
				flightListCopy:[],
			}
		},
		components:{
			FlightItem,
		},
		props:{
			flightList:{
				type:[Array],
				default (){
					return []
				}
			},
			flightMap:{
				type:[Object],
				default (){
					return {}
				}
			},
			priceMap:{
				type:[Object],
				default (){
					return {}
				}
			},
			planeMap:{
				type:[Object],
				default (){
					return {}
				}
			},
			// 选中的去程
			chooseFlight:{
				type:[Object],
				default (){
					return {}
				}
			},
			// 机票查询页问题
			tkQuery:{
				type:[Object],
				default (){
					return {}
				}
			}
		
			
		},
		watch:{
			flightList: {
			    handler (val, oldVal) {
					// console.log(val)
			     this.flightListCopy = this.$common.deepCopy(val)
			    },
			    deep: true,
				immediate: true, // 立即执行
			  },
			
		
		},
		mounted(){
			
			// this.flightListCopy = this.$common.deepCopy(this.flightList)
		},
		methods:{
			// 展开或者收缩某一个航班的舱位等级
			flightZhanKai(obj){
				this.$set(this.flightListCopy[obj.flightIndex],obj.attrName,obj.bool)
			},
			// 展开或者收缩某一个航班的某一个舱位等级的舱位和价格
			cabinTypeZhanKai(obj){
				this.$set(this.flightListCopy[obj.flightIndex]['cabinTypeList'][obj.cabinTypeIndex],obj.attrName,obj.bool)
			},
			// 选择航班中的某一个价格
			toChooseFlight(flightItem){
				
				this.$emit('chooseFlight',flightItem)
			},
			
		}
	}
</script>

<style lang="scss">
	.flight-list{
		background-color: white;
		
	}
</style>
